import React from 'react';
import {FormHeader} from "../components/PageHeader";
import {PACKAGE_ORG_ICON} from "../Constants";

export default class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			instanceUrl : "https://login.salesforce.com"
		};

		this.saveHandler = this.saveHandler.bind(this);
		this.nameChangeHandler = this.nameChangeHandler.bind(this);
	}

	// Lifecycle
	render() {
		let actions = [
			{handler: this.saveHandler, label: "Connect", spinning: this.state.isSaving},
			{handler: this.props.onCancel, label: "Cancel"}
		];
		return (
			<div>
				<div className="slds-modal slds-fade-in-open">
					<div className="slds-modal__container">
						<FormHeader type="Connect New Org" icon={PACKAGE_ORG_ICON} title={this.state.instanceUrl} actions={actions}/>
						<div className="slds-modal__content slds-p-around_medium">
							<div className="slds-form slds-form_stacked slds-wrap  slds-m-around--medium">
								<div className="slds-form-element">
									<label className="slds-form-element__label" htmlFor="name">URL</label>
									<div className="slds-form-element__control">
										<input autoFocus={true} className="slds-input" type="text" id="name" value={this.state.instanceUrl}
											   onChange={this.nameChangeHandler}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="slds-modal-backdrop slds-modal-backdrop--open"/>
			</div>
		);
	}
	// Handlers
	saveHandler() {
		this.setState({isSaving: true});
		this.props.onSave(this.state.instanceUrl);
	}

	nameChangeHandler(event) {
		this.setState({instanceUrl: event.target.value});
	}
}
