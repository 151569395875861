import React from 'react';

import * as packageService from '../services/PackageService';

import * as packageVersionService from "../services/PackageVersionService";
import * as upgradeItemService from "../services/UpgradeItemService";
import * as orgService from "../services/OrgService";

import {PACKAGE_ICON} from "../Constants";
import Tabs from "../components/Tabs";
import {HeaderField, RecordHeader} from '../components/PageHeader';
import {DataTableFilterHelp} from "../components/DataTableFilter";
import PackageVersionCard from "../packageversions/PackageVersionCard";
import OrgCard from "../orgs/OrgCard";
import UpgradeItemCard from "../upgrades/UpgradeItemCard";
import EditPackageWindow from "./EditPackageWindow";
import * as authService from "../services/AuthService";
import * as notifier from "../services/notifications";

export default class extends React.Component {
	constructor(props) {
		super(props);
	
		this.state = {
			user: authService.getSessionUser(this),
			pkg: {},
			isEditing: false
		};
		
		//packageService.requestById(props.match.params.packageId).then(pkg => this.setState({pkg}));

		this.fetchData = this.fetchData.bind(this);
		this.fetchVersions = this.fetchVersions.bind(this);
		this.fetchUpgradeItems = this.fetchUpgradeItems.bind(this);
		this.fetchOrgs = this.fetchOrgs.bind(this);
		this.fetchBlacklist = this.fetchBlacklist.bind(this);
		this.editHandler = this.editHandler.bind(this);
		this.cancelHandler = this.cancelHandler.bind(this);
		this.saveHandler = this.saveHandler.bind(this);
	}

	// Lifecycle
	componentDidMount() {
		notifier.on("packages", this.fetchData.bind(this));
		this.fetchData();
	}

	componentWillUnmount() {
		notifier.remove("packages", this.fetchData);
	}
	// Lifecycle
	render() {
		const {pkg, user} = this.state;
		let actions = [
			{label: "Edit Dependency Tier", handler: this.editHandler, disabled: user.read_only, }
		];
		return (
			<div>
				<RecordHeader type="Package" icon={PACKAGE_ICON} title={pkg.name}
							  actions={actions} parent={{label: "Packages", location: `/packages`}}>
					<HeaderField label="ID" value={pkg.sfid}/>
					<HeaderField label="Packaging Org ID" value={pkg.package_org_id}/>
					<HeaderField label="Package ID" value={pkg.package_id}/>
					<HeaderField label="Tier" value={pkg.dependency_tier}/>
				</RecordHeader>
				<div className="slds-card slds-p-around--xxx-small slds-m-around--medium">
					<Tabs id="PackageView">
						<div label="Versions">
							<PackageVersionCard onFetch={this.fetchVersions}/>
						</div>
						<div label="Customers">
							<OrgCard id="PackageMembers" title="Customers" withLicenseData={true} onFetch={this.fetchOrgs} onFetchBlacklist={this.fetchBlacklist}/>
						</div>
						<div label="Upgrades">
							<UpgradeItemCard id="PackageUpgradeItemCard" onFetch={this.fetchUpgradeItems} refetchOn="upgrade-items,upgrade-jobs"/>
						</div>
					</Tabs>
					<DataTableFilterHelp/>
				</div>
				{this.state.isEditing ?
					<EditPackageWindow pkg={pkg} onSave={this.saveHandler}
									   onCancel={this.cancelHandler}/> : ""}
			</div> 
		);
	}
	
	// Handlers
	fetchData() {
		packageService.requestById(this.props.match.params.packageId).then(
			pkg => this.setState({pkg})).catch(e => notifier.error(e.message || e));
	}

	fetchVersions() {
		return packageVersionService.findByPackage(this.props.match.params.packageId)
	}

	fetchUpgradeItems() {
		return upgradeItemService.findByPackage(this.props.match.params.packageId);
	};

	fetchOrgs() {
		return orgService.requestByPackage(this.props.match.params.packageId)
	}

	fetchBlacklist() {
		return orgService.requestByPackage(this.props.match.params.packageId, true)
	}
	editHandler() {
		this.setState({isEditing: true});
	}

	cancelHandler() {
		this.setState({isEditing: false});
	}
	saveHandler(pkg) {
		packageService.requestUpdate(pkg).then((pkg) => {
			this.setState({pkg, isEditing: false});
		});
	}
}